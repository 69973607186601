.student {
  font-family: "Lato", sans-serif !important;
}

.public {
  font-family: "Poppins", sans-serif !important;
}
.middle {
  transform: translate(-50%, -50%);
}
.card {
  cursor: pointer;
  width: 320px;
  height: 260px;
}
.front,
.back {
  width: 100%;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  position: absolute;
  transition: transform 0.6s linear;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(83, 126, 197, 0.47);
  background-color: #fff;
}
.front img {
  height: 100%;
}
.front {
  transform: perspective(600px) rotateY(0deg);
  display: flex;
  align-items: center;
  justify-content: center;
}
.back {
  background: linear-gradient(
    -145deg,
    rgb(253, 200, 48) 0%,
    rgb(243, 115, 53) 100%
  );
  transform: perspective(600px) rotateY(180deg);
}
.back-content {
  color: #2c3e50;
  text-align: center;
  width: 100%;
}
.sm {
  margin: 20px 0;
}
.sm a {
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #2c3e50;
  font-size: 18px;
  transition: 0.4s;
  border-radius: 50%;
}
.sm a:hover {
  background: #2c3e50;
  color: white;
}
.card:hover > .front {
  transform: perspective(600px) rotateY(-180deg);
}
.card:hover > .back {
  transform: perspective(600px) rotateY(0deg);
}

button:focus {
  outline: none !important;
}

/* scroll bar */
.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #010038;
}

.scroll::-webkit-scrollbar {
  width: 5px;
}

.scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: transparent;
}

.scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #010038;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.349);
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.349);
  padding-inline: 2px;
}
.scroll::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(233, 228, 228, 0.8);
}

/* scroll x */

.scrollX {
  overflow-y: hidden;
  overflow-x: scroll;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #010038;
}

.scrollX::-webkit-scrollbar {
  height: 7px;
}

.scrollX::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: transparent;
}

.scrollX::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #010038;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.349);
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.349);
  padding: 2px;
}
.scrollX::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(233, 228, 228, 0.8);
}

/* header */

.header:hover .header-img {
  opacity: 1;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.slider {
  width: 90%;
  margin: auto !important;
}

.slider .slick-slide {
  padding: 5px !important;
}

.chapters-slider {
  display: flex !important;
}

.teacher-slider .slick-center {
  transform: scale(1.4) !important;
  transition-duration: 8ms;
  transition-delay: 8ms;
}

.splash {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: -85px;
  clip-path: path(
    "M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z"
  );
}

.card {
  font-size: 164px;
  width: 300px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

body {
  overflow-x: hidden;
}
